import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";

export default function TemplateFormDialog({ data, onClose, onSubmit }) {
  const [sms, setSms] = useState({
    name: data?.name || "",
    content: data?.content || "",
  });

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>{`${data ? "Edit" : "Create"} SMS Template`}</DialogTitle>
      <DialogContent sx={{ width: 500, maxWidth: "100vw" }}>
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          fullWidth
          variant="standard"
          value={sms.name}
          onChange={(e) =>
            setSms((v) => ({
              ...v,
              name: e.target.value,
            }))
          }
        />
        <TextField
          multiline
          rows={4}
          autoFocus
          sx={{ mt: 3 }}
          label="Text"
          fullWidth
          variant="standard"
          value={sms.content}
          onChange={(e) =>
            setSms((v) => ({
              ...v,
              content: e.target.value,
            }))
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onSubmit(sms)}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
}
