import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import moment from "moment";
import { getCommunities } from "../apis/communities";
import { Link } from "react-router-dom";
import LoadableSection from "../components/shared/LoadableSection";

export default function Communities() {
  const [communities, setCommunities] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCommunities = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await getCommunities();
      setCommunities(data);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCommunities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Typography mb={4} fontWeight={600} fontSize={24}>
        Communities
      </Typography>
      <LoadableSection loading={loading}>
        {communities?.length && (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {communities.map((row, index) => (
                  <TableRow
                    key={index}
                    component={Link}
                    to={`/dashboard/users?community=${row.id}`}
                    hover
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      textDecoration: "none",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row.institution_name}
                    </TableCell>
                    <TableCell>{row.institution_address}</TableCell>
                    <TableCell>{row.institution_city}</TableCell>
                    <TableCell>{row.institution_state}</TableCell>
                    <TableCell>{row.institution_country}</TableCell>
                    <TableCell>
                      {moment(row.created_at).format("YYYY-MM-DD")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </LoadableSection>
    </div>
  );
}
