import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { createSms, deleteSms, getSmss, updateSms } from "../apis/sms";
import { useConfirm } from "../contexts/Confirm";
import TemplateFormDialog from "../components/SmsTemplates/TemplateFormDialog";
import { useSnackbar } from "../contexts/Snackbar";

export default function SmsTemplates() {
  const [smsForm, setSmsForm] = useState({
    show: false,
    template: null,
  });
  const [templates, setTemplates] = useState([]);
  const confirm = useConfirm();
  const { showSnackbar } = useSnackbar();

  const handleSave = async (sms) => {
    try {
      if (!smsForm.template) {
        await createSms(sms);
      } else {
        await updateSms(smsForm.template.id, sms);
      }
      showSnackbar({
        severity: "success",
        message: "The template has been saved.",
      });
    } catch (err) {
      console.error(err);
      showSnackbar({
        severity: "error",
        message: "Failed to save the template.",
      });
    }
    setSmsForm({ show: false, template: null });
    fetchSMS();
  };

  const handleDelete = async (template) => {
    if (
      await confirm({
        title: "Delete Template?",
      })
    ) {
      try {
        await deleteSms(template.id);
        showSnackbar({
          severity: "success",
          message: "The template has been removed.",
        });
      } catch (err) {
        console.error(err);
        showSnackbar({
          severity: "error",
          message: "Failed to delete the template.",
        });
      }
      fetchSMS();
    }
  };

  const fetchSMS = async () => {
    const { data } = await getSmss();
    setTemplates(data.data);
  };

  useEffect(() => {
    fetchSMS();
  }, []);

  return (
    <div>
      <Grid container justifyContent="space-between" alignItems="center" mb={4}>
        <Grid item>
          <Typography fontWeight={600} fontSize={24}>
            SMS Templates
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() =>
              setSmsForm({
                show: true,
                template: null,
              })
            }
          >
            Create a template
          </Button>
        </Grid>
      </Grid>

      {templates?.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Content</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {templates.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    textDecoration: "none",
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.content}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() =>
                        setSmsForm({
                          show: true,
                          template: row,
                        })
                      }
                    >
                      <EditIcon color="info" />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(row)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {smsForm.show && (
        <TemplateFormDialog
          data={smsForm.template}
          onClose={() =>
            setSmsForm({
              show: false,
              template: null,
            })
          }
          onSubmit={handleSave}
        />
      )}
    </div>
  );
}
