import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import LoadableSection from "../components/shared/LoadableSection";
import { useEffect, useState } from "react";
import { getUser } from "../apis/users";

export default function ScenarioProgress() {
  const { id } = useParams();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [scenarios, setScenarios] = useState([]);

  const fetchUser = async () => {
    setLoading(true);

    try {
      const {
        data: { deva },
      } = await getUser(id);
      setUser(deva);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div>
      <LoadableSection loading={loading}>
        {user && (
          <Card sx={{ my: 4 }}>
            <CardHeader title="User Information" />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Typography>Name</Typography>
                  <Typography fontWeight="bold">
                    {user.first_name} {user.last_name}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography>Scenarios Completed</Typography>
                  <Typography fontWeight="bold">0</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>Time in Current Scenario</Typography>
                  <Typography fontWeight="bold">-</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography>Last time of play</Typography>
                  <Typography fontWeight="bold">-</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </LoadableSection>
      <LoadableSection loading={loading}>
        <Card>
          <CardHeader title="Scenario Progress" />
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Scenario</TableCell>
                  <TableCell>Total time in scenario</TableCell>
                  <TableCell>Time in NW options</TableCell>
                  <TableCell>Scenario Path</TableCell>
                  <TableCell># of endpoints completed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {scenarios.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.first_name}
                    </TableCell>
                    <TableCell>{row.last_name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.currentScenario}</TableCell>
                    <TableCell>{row.completedScenarios}</TableCell>
                    <TableCell>{row.lastTimePlay}</TableCell>
                    <TableCell>{row.supportActionsTaken}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </LoadableSection>
    </div>
  );
}
