import {
  AppBar,
  Avatar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import LogoutIcon from "@mui/icons-material/Logout";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import PersonIcon from "@mui/icons-material/Person";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import SmsIcon from "@mui/icons-material/Sms";
import React, { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";

import { useAuth } from "../contexts/AuthContext";

const drawerWidth = 240;

const navs = [
  {
    name: "Profile",
    link: "/profile",
    icon: <PersonIcon />,
  },
  {
    name: "Users",
    link: "/users",
    role: 2,
    icon: <PeopleAltRoundedIcon />,
  },
  {
    name: "Communities",
    link: "/communities",
    role: 2,
    icon: <WorkspacesIcon />,
  },
  {
    name: "SMS",
    link: "/sms",
    role: 2,
    icon: <SmsIcon />,
  },
];

export default function Dashboard() {
  const { user, logout } = useAuth();
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        elevation={1}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            CarePlay Backoffice
          </Typography>
          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={`${user.display_name}`}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  variant="rounded"
                  src=""
                  sx={{ bgcolor: deepOrange[300], color: "white" }}
                >
                  {`${user.display_name.slice(0, 2)}`}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                onClick={() => {
                  handleCloseUserMenu();
                  logout();
                }}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText>Log out</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            <NavItem {...navs[0]} />
          </List>
          <List subheader={<ListSubheader>Admin</ListSubheader>}>
            {navs
              .slice(1)
              .map(
                (nav) =>
                  (!nav.role || nav.role === user.role) && (
                    <NavItem key={nav.name} {...nav} />
                  )
              )}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

const NavItem = ({ link, icon, name }) => (
  <NavLink
    key={link}
    to={`/dashboard${link}`}
    style={{
      textDecoration: "none",
    }}
  >
    {({ isActive }) => (
      <ListItem
        disablePadding
        sx={() => ({
          display: "block",
          background: isActive ? "rgba(0,0,0,0.2)" : "transparent",
        })}
      >
        <ListItemButton color={isActive ? "primary" : "secondary"}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>
            <Typography
              sx={{
                color: "black",
                textDecoration: "none",
              }}
            >
              {name}
            </Typography>
          </ListItemText>
        </ListItemButton>
      </ListItem>
    )}
  </NavLink>
);
