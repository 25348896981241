import { api } from "./api";

export const createSms = (data) => api.post(`/SmsTemplates/add`, data);
export const updateSms = (id, data) =>
  api.patch(`/SmsTemplates/edit/${id}`, data);
export const deleteSms = (id) => api.delete(`/SmsTemplates/delete/${id}`);
export const getSms = (id) => api.get(`/SmsTemplates/${id}`);
export const getSmss = (params) =>
  api.get("/SmsTemplates/", {
    params,
  });

export const sendSms = (id, numbers) =>
  api.post("/SmsTemplates/sendSms", {
    id,
    numbers,
  });
