import { styled } from "@mui/material/styles";
import { memo } from "react";
import LoadingSpinner from "./LoadingSpinner";

const Wrapper = styled("div")((t) => ({
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "rgba(255,255,255,0.6)",
  zIndex: 9999,
}));

const LoadingScreen = styled("div")((t) => ({
  height: "100vh",
}));

export default memo(() => {
  return (
    <LoadingScreen>
      <Wrapper>
        <LoadingSpinner />
      </Wrapper>
    </LoadingScreen>
  );
});
