import { Card, CardContent, Container } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import LoginForm from "../components/LoginForm";
import { useAuth } from "../contexts/AuthContext";
import { useSnackbar } from "../contexts/Snackbar";

export default function Login() {
  const { login } = useAuth();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLogin = async (data) => {
    setLoading(true);

    try {
      await login({ ...data, _key: null });
      navigate("/dashboard/profile");
    } catch (err) {
      console.error(err);
      showSnackbar({
        severity: "error",
        message: err?.response?.data?.message || "Failed to login",
      });
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="sm" sx={{ marginX: "auto" }}>
      <Card sx={{ mt: 12 }}>
        <CardContent>
          <LoginForm onLogin={handleLogin} loading={loading} />
        </CardContent>
      </Card>
    </Container>
  );
}
