import { createContext, useContext, useEffect, useState } from "react";
import { login as loginApi } from "../apis/auth";
import { getUser } from "../apis/users";

export const AuthContext = createContext({
  loading: false,
  user: null,
  login: async () => null,
  logout: () => null,
  checkUser: async () => null,
});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    user: null,
    loading: true,
  });

  const checkUser = async () => {
    if (!localStorage.getItem("token") || !localStorage.getItem("id")) {
      setAuth({
        user: null,
        loading: false,
      });
      return;
    }

    try {
      const id = localStorage.getItem("id");
      const {
        data: { deva, careplay },
      } = await getUser(id);
      setAuth({
        user: {
          ...deva,
          ...careplay,
        },
        loading: false,
      });
    } catch (err) {
      console.error(err);
      setAuth({
        user: null,
        loading: false,
      });
    }
  };

  const login = async (logindata) => {
    try {
      const {
        data: { data },
      } = await loginApi(logindata);
      const { token, id } = data;
      localStorage.setItem("token", token);
      localStorage.setItem("id", id);
      await checkUser();
    } catch (err) {
      console.error(err);
      setAuth({
        user: null,
        loading: false,
      });
      throw err;
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    setAuth({
      user: null,
      loading: false,
    });
  };

  useEffect(() => {
    checkUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...auth,
        login,
        logout,
        checkUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
