import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { memo } from "react";

export default memo(
  ({
    options: { title, text, okText, cancelText },
    onClose,
    open,
    onCancel,
    onConfirm,
  }) => {
    return (
      <Dialog onClose={onClose} open={open}>
        <DialogTitle onClose={onClose}>
          <Typography color="primary" fontWeight={500} textAlign="center">
            {title}
          </Typography>
        </DialogTitle>
        {text && (
          <DialogContent
            sx={(t) => ({
              textAlign: "center",
              fontSize: 14,
            })}
          >
            {text}
          </DialogContent>
        )}
        <DialogActions>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <Button
                sx={{
                  minWidth: 140,
                }}
                autoFocus
                onClick={onConfirm}
                color="error"
                variant="contained"
              >
                {okText}
              </Button>
            </Grid>
            <Grid item>
              {cancelText && (
                <Button
                  onClick={onCancel}
                  variant="outlined"
                  sx={{
                    minWidth: 140,
                  }}
                >
                  {cancelText}
                </Button>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
);
