import { Button, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

export default function LoginForm({ onLogin, loading }) {
  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = (data) => onLogin(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography component="h1" variant="h5">
        Sign in
      </Typography>
      <Controller
        name="email"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            margin="normal"
            fullWidth
            label="Email"
            autoComplete="email"
            autoFocus
            {...field}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField
            margin="normal"
            fullWidth
            label="Password"
            type="password"
            autoComplete="current-password"
            {...field}
          />
        )}
      />
      <Button
        sx={{ marginTop: 3 }}
        type="submit"
        fullWidth
        variant="contained"
        size="large"
        disabled={loading}
      >
        Login
      </Button>
    </form>
  );
}
