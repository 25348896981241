import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "./App.css";
import Login from "./view/Login";
import Users from "./view/Users";
import { useAuth } from "./contexts/AuthContext";
import Dashboard from "./view/Dashboard";
import Communities from "./view/Communities";
import Loading from "./components/Loading";
import Profile from "./view/Profile";
import ScenarioProgress from "./view/ScenarioProgress";
import SmsTemplates from "./view/SmsTemplates";

function RequireAuth({ children }) {
  const { user, loading } = useAuth();

  return loading ? <Loading /> : user ? children : <Navigate to="/" replace />;
}

function RequireNoneAuth({ children }) {
  const { user, loading } = useAuth();

  return loading ? (
    <Loading />
  ) : user ? (
    <Navigate to="/dashboard/profile" replace />
  ) : (
    children
  );
}

function RequireSuperAdmin({ children }) {
  const { user, loading } = useAuth();

  return loading ? (
    <Loading />
  ) : user?.role === 2 ? (
    children
  ) : (
    <Navigate to="/" replace />
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RequireNoneAuth>
        <Login />
      </RequireNoneAuth>
    ),
  },
  {
    path: "dashboard",
    element: (
      <RequireAuth>
        <Dashboard />
      </RequireAuth>
    ),
    children: [
      {
        path: "profile",
        element: (
          <RequireAuth>
            <Profile />
          </RequireAuth>
        ),
      },
      {
        path: "users",
        element: (
          <RequireSuperAdmin>
            <Users />
          </RequireSuperAdmin>
        ),
      },
      {
        path: "users/:id/progress",
        element: (
          <RequireSuperAdmin>
            <ScenarioProgress />
          </RequireSuperAdmin>
        ),
      },
      // {
      //   path: "communities/:id",
      //   element: (
      //     <RequireSuperAdmin>
      //       <Users />
      //     </RequireSuperAdmin>
      //   ),
      // },
      {
        path: "communities",
        element: (
          <RequireSuperAdmin>
            <Communities />
          </RequireSuperAdmin>
        ),
      },
      {
        path: "sms",
        element: (
          <RequireSuperAdmin>
            <SmsTemplates />
          </RequireSuperAdmin>
        ),
      },
      {
        path: "",
        element: <Navigate replace to="/dashboard/profile" />,
      },
    ],
  },
]);

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
