import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import ReactPhoneInput from "react-phone-input-material-ui";

import { useAuth } from "../contexts/AuthContext";
import { updateUser } from "../apis/users";

export default function Profile() {
  const { user, checkUser } = useAuth();
  const [editing, setEditing] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleSave = async () => {
    await updateUser(user.id, { phone: phoneNumber });
    checkUser();
    setEditing(false);
  };

  useEffect(() => {
    setPhoneNumber(user?.phone ?? "");
  }, [user]);

  return (
    <Container maxWidth="md">
      <Card>
        <CardHeader
          title={
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography fontWeight={600} fontSize={24}>
                  Profile
                </Typography>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={() => setEditing(true)}>
                  Edit
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          <Typography>First Name</Typography>
          <Typography fontWeight="bold">{user.first_name}</Typography>
          <Typography mt={3}>Last Name</Typography>
          <Typography fontWeight="bold">{user.last_name}</Typography>
          <Typography mt={3}>Email</Typography>
          <Typography fontWeight="bold">{user.email}</Typography>
          <Typography mt={3}>Phone Number</Typography>
          {editing ? (
            <Box mt={2} maxWidth={320}>
              <ReactPhoneInput
                defaultCountry="us"
                component={TextField}
                value={phoneNumber}
                onChange={setPhoneNumber}
              />
            </Box>
          ) : (
            <Typography fontWeight="bold">{user.phone ?? "-"}</Typography>
          )}
        </CardContent>
        {editing && (
          <CardActions>
            <Button onClick={() => setEditing(false)}>Cancel</Button>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          </CardActions>
        )}
      </Card>
    </Container>
  );
}
