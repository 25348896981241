import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { getUser, getUsers } from "../apis/users";
import { getSmss, sendSms } from "../apis/sms";
import { useSnackbar } from "../contexts/Snackbar";
import Loading from "../components/Loading";
import UserFilters from "../components/Users/Filters";
import LoadableSection from "../components/shared/LoadableSection";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [community, setCommunity] = useState();
  const [query] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate] = useState("");
  const [showSendSms, setShowSendSms] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSendSms = async () => {
    const numbers = selected
      .map((id) => users.find((u) => u.id === id).phone)
      .filter(Boolean);

    if (!numbers.length) {
      showSnackbar({
        severity: "warning",
        message: "Users don't have phone numbers",
      });
      setShowSendSms(false);
      return;
    }
    setLoading(true);

    try {
      await sendSms(template, numbers);
      showSnackbar({
        severity: "success",
        message: "SMS has been sent.",
      });
    } catch (err) {
      console.error(err);
      showSnackbar({
        severity: "error",
        message: "Failed to send SMS",
      });
    }
    setShowSendSms(false);
    setLoading(false);
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const communityId = query.get("community");

      if (communityId) {
        const {
          data: { deva: community },
        } = await getUser(communityId);
        setCommunity(community);
      }

      const {
        data: { deva, careplay },
      } = await getUsers(communityId ? { communityId } : {});
      setUsers(
        deva.map((u) => ({
          ...u,
          ...(careplay.find((cu) => cu.id === u.id) ?? {
            not_in_careplay: true,
          }),
        }))
      );
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const fetchSMS = async () => {
    const { data } = await getSmss();
    setTemplates(data.data);
  };

  const currentPage = searchParams.get("page") ? +searchParams.get("page") : 1;

  useEffect(() => {
    fetchUsers();
    fetchSMS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredUsers = useMemo(
    () =>
      users.filter((user) => {
        let filtered = true;

        if (searchParams.get("search")) {
          filtered = (user.first_name + " " + user.last_name)
            .toLowerCase()
            .includes(searchParams.get("search"));
        }

        if (!filtered) {
          return false;
        }

        const careplayStatus = searchParams.get("careplayStatus");

        if (!careplayStatus) {
          return true;
        }

        if (careplayStatus === "in_careplay") {
          filtered = !user.not_in_careplay;
        } else if (careplayStatus === "not_in_careplay") {
          filtered = user.not_in_careplay;
        } else if (careplayStatus === "all") {
          filtered = true;
        } else {
          filtered = false;
        }
        return filtered;
      }),
    [searchParams, users]
  );

  return (
    <div>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography fontWeight={600} fontSize={24}>
            Users
          </Typography>
        </Grid>
        <Grid item>
          {selected.length > 0 && (
            <Button variant="contained" onClick={() => setShowSendSms(true)}>
              Send SMS
            </Button>
          )}
        </Grid>
      </Grid>
      {community && (
        <Card sx={{ my: 3 }}>
          <CardHeader title="Community Information" />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography>Name</Typography>
                <Typography fontWeight="bold">
                  {community.institution_name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>Address</Typography>
                <Typography fontWeight="bold">
                  {community.institution_address}, {community.institution_city},{" "}
                  {community.institution_state} {community.institution_country}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      <UserFilters />

      <LoadableSection loading={loading}>
        <>
          {filteredUsers?.length > 0 && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Scenarios Completed</TableCell>
                    <TableCell>Time in current scenario</TableCell>
                    <TableCell>Last time of play</TableCell>
                    <TableCell>Support actions taken</TableCell>
                    <TableCell>In Careplay</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers
                    .slice((currentPage - 1) * 8, currentPage * 8)
                    .map((row) => (
                      <TableRow
                        component={Link}
                        to={`/dashboard/users/${row.id}/progress`}
                        hover
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          textDecoration: "none",
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selected.includes(row.id)}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              selected.includes(row.id)
                                ? setSelected(
                                    selected.filter((id) => id !== row.id)
                                  )
                                : setSelected([...selected, row.id]);
                            }}
                          />
                        </TableCell>
                        <TableCell>{row.first_name}</TableCell>
                        <TableCell>{row.last_name}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.currentScenario}</TableCell>
                        <TableCell>{row.completedScenarios}</TableCell>
                        <TableCell>{row.lastTimePlay}</TableCell>
                        <TableCell>{row.supportActionsTaken}</TableCell>
                        <TableCell>
                          <Chip
                            color={row.not_in_careplay ? "error" : "success"}
                            label={row.not_in_careplay ? "No" : "Yes"}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Divider />
              <Grid container justifyContent="end">
                <Pagination
                  sx={{
                    pt: 3,
                    pb: 2,
                  }}
                  count={Math.ceil(filteredUsers.length / 8)}
                  page={currentPage}
                  onChange={(_, page) => {
                    searchParams.set("page", page);
                    setSearchParams(searchParams);
                  }}
                />
              </Grid>
            </TableContainer>
          )}
        </>
      </LoadableSection>

      {loading && <Loading />}

      <Dialog open={showSendSms} onClose={() => setShowSendSms(false)}>
        <DialogTitle>Select SMS Template</DialogTitle>
        <DialogContent
          sx={{ width: 500, maxWidth: "100vw" }}
          style={{ paddingTop: 24 }}
        >
          <TextField
            select
            required
            fullWidth
            value={template}
            label="Template"
            onChange={(e) => setTemplate(e.target.value)}
          >
            {templates.map((t) => (
              <MenuItem value={t.id} key={t.id}>
                {t.name}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowSendSms(false)}>Cancel</Button>
          <Button onClick={handleSendSms} disabled={!template}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
