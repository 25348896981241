import { Grid, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export default function UserFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState({
    search: searchParams.get("search") ?? "",
    careplayStatus: searchParams.get("careplayStatus") ?? "",
  });

  useEffect(() => {
    console.log(filter)
    setSearchParams(filter);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Grid container py={4} spacing={2}>
      <Grid item xs={3}>
        <TextField
          label="Search"
          fullWidth
          value={filter.search}
          onChange={(e) =>
            setFilter({
              ...filter,
              search: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          select
          fullWidth
          value={filter.careplayStatus}
          label="Careplay Status"
          onChange={(e) =>
            setFilter({
              ...filter,
              careplayStatus: e.target.value,
            })
          }
        >
          <MenuItem value={"all"}>All</MenuItem>
          <MenuItem value="in_careplay">In Careplay</MenuItem>
          <MenuItem value="not_in_careplay">Not in Careplay</MenuItem>
          <MenuItem value="completed_careplay">Completed Careplay</MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
}
